import { useCallback, useEffect, useState } from "react";
import { Heading, Subheading } from "../../components/heading";
import { Button } from "../../components/button";
import { Divider } from "../../components/divider";
import {
    Description,
    Field,
    FieldGroup,
    Fieldset,
    Label,
} from "../../components/fieldset";
import { Input } from "../../components/input";
import { useNavigate, useParams } from "react-router-dom";
import { FuelType, JUHUU } from "@juhuu/sdk-ts";
import { useJUHUU } from "../../context/JuhuuContext";
import LatLongPicker from "../../components/lat-long-picker";
import { Listbox, ListboxLabel, ListboxOption } from "../../components/listbox";

interface DeviceEditRouteProps {}

const DeviceEditRoute: React.FC<DeviceEditRouteProps> = () => {
    const navigate = useNavigate();
    const [latitude, setLatitude] = useState<number | null>(null);
    const [longitude, setLongitude] = useState<number | null>(null);
    const [deviceSaveLoading, setDeviceSaveLoading] = useState(false);
    const { deviceId } = useParams<{ deviceId: string }>();
    const juhuu = useJUHUU();
    const [device, setDevice] = useState<JUHUU.Device.Object | null>(null);
    const [name, setName] = useState<string>(device ? device.name : "");
    const [status, setStatus] = useState<
        JUHUU.Device.Object["status"] | undefined
    >(undefined);
    const [description, setDescription] = useState<string | null>(null);
    const [fuelType, setFuelType] = useState<FuelType | undefined>(undefined);
    const [fuelLevel, setFuelLevel] = useState<number | undefined>(undefined);
    const [rangeRemaining, setRangeRemaining] = useState<number | null>(null);
    const [connectorId, setConnectorId] = useState<string | null>(null);
    const [connectorParameter, setConnectorParameter] = useState<string | null>(
        null,
    );

    const handleUpdate = useCallback(async () => {
        if (device === null) {
            return;
        }
        setDeviceSaveLoading(true);

        console.log(status);

        const query = await juhuu.devices.update({
            deviceId: device.id,
            name: name,
            status: status,
            description: description,
            fuel: {
                type: fuelType,
                level: fuelLevel,
            },
            latitude: latitude ?? undefined,
            longitude: longitude ?? undefined,
            rangeRemaining: rangeRemaining,
            connectorId: connectorId,
            connectorParameter: connectorParameter,
        });

        setDeviceSaveLoading(false);

        if (query.ok === false) {
            return;
        }

        setDevice(query.data.device);
        navigate(`./../../${device?.id}`);
    }, [
        device,
        navigate,
        juhuu,
        name,
        status,
        description,
        fuelType,
        fuelLevel,
        latitude,
        longitude,
        rangeRemaining,
        connectorId,
        connectorParameter,
    ]);

    const handleRefresh = useCallback(async () => {
        if (deviceId === undefined) {
            return;
        }

        const query = await juhuu.devices.retrieve({
            deviceId: deviceId,
        });

        if (query.ok === false) {
            return;
        }

        setDevice(query.data.device);
        setName(query.data.device.name);
        setStatus(query.data.device.status);
        setDescription(query.data.device.description);
        setFuelType(query.data.device.fuel?.type);
        setFuelLevel(query.data.device.fuel?.level);
        setRangeRemaining(query.data.device.rangeRemaining);
        setConnectorId(query.data.device.connectorId);
        setConnectorParameter(query.data.device.connectorParameter);
        setLatitude(query.data.device.location?.coordinates[1] ?? null);
        setLongitude(query.data.device.location?.coordinates[0] ?? null);
    }, [deviceId, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    return (
        <>
            <Heading>Device settings</Heading>
            <Divider className="mb-4" />
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Device Name</Label>
                            <Description>
                                Change the name of your product
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                disabled={deviceSaveLoading}
                                value={name}
                                placeholder="My Device Name"
                                type="text"
                                onChange={(event) =>
                                    setName(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Device Description</Label>
                            <Description>
                                Change the description of your product
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                disabled={deviceSaveLoading}
                                value={description ?? ""}
                                placeholder="device description"
                                type="text"
                                onChange={(event) =>
                                    setDescription(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Subheading>Status</Subheading>
            <Divider className="mb-4" />
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Status</Label>
                            <Description>
                                Change the status of your product
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Field>
                                <Label>Sector</Label>
                                <Listbox
                                    name="sector"
                                    value={status}
                                    onChange={setStatus}
                                >
                                    <ListboxOption value="running">
                                        <ListboxLabel>Running</ListboxLabel>
                                    </ListboxOption>
                                    <ListboxOption value="sleeping">
                                        <ListboxLabel>Sleeping</ListboxLabel>
                                    </ListboxOption>
                                    <ListboxOption value="shutdown">
                                        <ListboxLabel>Shutdown</ListboxLabel>
                                    </ListboxOption>
                                </Listbox>
                            </Field>
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>GPS-Location</Label>
                            <Description>
                                Set the GPS coordinates of your location.
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <LatLongPicker
                                latitude={latitude}
                                longitude={longitude}
                                setLatitude={setLatitude}
                                setLongitude={setLongitude}
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Subheading>Fuel</Subheading>
            <Divider className="mb-4" />
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Fuel Type</Label>
                            <Description>
                                Change the type of your devices fuel
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Field>
                                <Label>Type</Label>
                                <Listbox
                                    name="sector"
                                    value={fuelType}
                                    onChange={setFuelType}
                                >
                                    <ListboxOption value="battery">
                                        <ListboxLabel>Battery</ListboxLabel>
                                    </ListboxOption>
                                    <ListboxOption value="diesel">
                                        <ListboxLabel>Diesel</ListboxLabel>
                                    </ListboxOption>
                                    <ListboxOption value="gasoline">
                                        <ListboxLabel>Gasoline</ListboxLabel>
                                    </ListboxOption>
                                    <ListboxOption value="hydrogen">
                                        <ListboxLabel>Hydrogen</ListboxLabel>
                                    </ListboxOption>
                                    <ListboxOption value="fossil">
                                        <ListboxLabel>Fossil</ListboxLabel>
                                    </ListboxOption>
                                </Listbox>
                            </Field>
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Fuel Level</Label>
                            <Description>
                                Set the fuel level of your product 0-100 %
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                disabled={deviceSaveLoading}
                                type="number"
                                value={fuelLevel ?? 0}
                                placeholder="0"
                                onChange={(event) =>
                                    setFuelLevel(
                                        parseFloat(event.target.value ?? 0),
                                    )
                                }
                                min={0}
                                max={100}
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Range Remaining</Label>
                            <Description>
                                Set the remaining range of your device
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                disabled={deviceSaveLoading}
                                value={rangeRemaining ?? 0}
                                placeholder="0"
                                type="number"
                                onChange={(event) =>
                                    setRangeRemaining(
                                        parseFloat(event.target.value ?? 0),
                                    )
                                }
                                min={0}
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Subheading>Connector</Subheading>
            <Divider className="mb-4" />
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Connector Id</Label>
                            <Description>
                                Change the Connector Id of your device
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                disabled={deviceSaveLoading}
                                value={connectorId ?? "connector Id"}
                                placeholder="connector Id"
                                type="text"
                                onChange={(event) =>
                                    setConnectorId(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Fieldset className="mb-12">
                <FieldGroup>
                    <Field className="flex flex-row justify-between gap-4">
                        <div className="w-1/2 ">
                            <Label>Connector Parameter</Label>
                            <Description>
                                Change the Connector Parameter of your device
                            </Description>
                        </div>
                        <div className="w-1/2">
                            <Input
                                disabled={deviceSaveLoading}
                                value={
                                    connectorParameter ?? "connector Paramter"
                                }
                                placeholder="connector Parameter"
                                type="text"
                                onChange={(event) =>
                                    setConnectorParameter(event.target.value)
                                }
                            />
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
            <Divider className="mb-4" />
            <div className="flex justify-end gap-4">
                <Button onClick={() => navigate(-1)} plain>
                    Cancel
                </Button>
                <Button onClick={handleUpdate}>Save changes</Button>
            </div>
        </>
    );
};

export default DeviceEditRoute;
