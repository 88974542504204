import { useCallback, useEffect, useState } from "react";

import { JUHUU, PaymentRefundReason } from "@juhuu/sdk-ts";
import { Heading, Subheading } from "../../components/heading";
import { useParams } from "react-router-dom";
import { Text } from "../../components/text";
import BadgeStatusPayment from "./BadgeStatusPayment";
import Timestamp from "../../formatters/Timestamp";
import PaymentMethod from "../../formatters/PaymentMethod";
import Amount from "../../formatters/Amount";
import { Divider } from "../../components/divider";
import {
    DescriptionDetails,
    DescriptionList,
    DescriptionTerm,
} from "../../components/description-list";
import Timeline from "./Timeline";
import { useJUHUU } from "../../context/JuhuuContext";
import { Button } from "../../components/button";
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogDescription,
    DialogTitle,
} from "../../components/dialog";
import { Field, FieldGroup, Fieldset, Label } from "../../components/fieldset";
import { CheckCircleIcon } from "@heroicons/react/16/solid";
import { Badge } from "../../components/badge";
import AmountPicker from "../../components/amount-picker";
import DropdownPicker from "../../components/dropdown-picker";

interface PaymentRouteProps {}

const PaymentRoute: React.FC<PaymentRouteProps> = () => {
    const [refundSuccessful, setRefundSuccessful] = useState<boolean>(false);
    const [openRefundDialog, setOpenRefundDialog] = useState<boolean>(false);
    const [createRefundLoading, setCreateRefundLoading] =
        useState<boolean>(false);
    const [amountToRefund, setAmountToRefund] = useState<number>(0);
    const [refundReason, setRefundReason] = useState<
        JUHUU.PaymentRefund.Object["reason"] | null
    >(null);
    const [payment, setPayment] = useState<JUHUU.Payment.Object | null>(null);
    const { paymentId } = useParams<{ paymentId: string }>();
    const juhuu = useJUHUU();
    const handleRefresh = useCallback(async () => {
        if (paymentId === undefined) {
            return;
        }

        const query = await juhuu.payments.retrieve({
            paymentId: paymentId,
        });

        if (query.ok === false) {
            return;
        }

        setPayment(query.data.payment);
    }, [paymentId, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    const handleCreateRefund = useCallback(async () => {
        setRefundSuccessful(false);

        if (payment === null) {
            return;
        }

        if (refundReason === null) {
            return;
        }

        setCreateRefundLoading(true);
        const query = await juhuu.paymentRefunds.create({
            amountToArriveAtUser: amountToRefund,
            paymentId: payment.id,
            reason: refundReason,
        });
        setCreateRefundLoading(false);

        if (query.ok === false) {
            return;
        }

        setAmountToRefund(0);
        setRefundReason(null);
        setRefundSuccessful(true);
    }, [payment, juhuu, amountToRefund, refundReason]);

    useEffect(() => {
        if (openRefundDialog === false && refundSuccessful === true) {
            setRefundSuccessful(false);
        }
    }, [openRefundDialog, refundSuccessful]);

    const handleDownloadInvoice = useCallback(async () => {
        if (paymentId === undefined) {
            return;
        }

        const query = await juhuu.payments.retrieveInvoiceUrl({
            paymentId: paymentId,
        });

        if (query.ok === false) {
            return;
        }

        window.open(query.data.invoiceUrl, "_blank");
    }, [paymentId, juhuu]);

    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4 pb-2">
                <div className="flex gap-4 items-center">
                    <Heading>Payment</Heading>
                    <BadgeStatusPayment status={payment?.status} />
                    {payment?.amountRefunded !== undefined &&
                        payment.amountRefunded > 0 && (
                            <Badge>
                                Refunded{" "}
                                <Amount
                                    amount={payment?.amountRefunded}
                                    currencyCode={payment?.currencyCode}
                                />
                            </Badge>
                        )}
                </div>

                <div className="flex items-center gap-4">
                    <Button
                        onClick={handleDownloadInvoice}
                        disabled={payment?.invoicePdfId === null}
                    >
                        Download invoice
                    </Button>
                    <Button onClick={() => setOpenRefundDialog(true)}>
                        Refund
                    </Button>
                </div>
            </div>
            <Divider />
            <div className="flex justify-start flex-row items-center py-4">
                <div className="flex justfy-start flex-col items-start pr-8">
                    <Text>Payment method</Text>
                    <Text>
                        <PaymentMethod paymentMethod={payment?.paymentMethod} />
                    </Text>
                </div>
                <div className="flex justfy-start flex-col items-start border-l-2 pr-8 pl-2">
                    <Text>Created</Text>
                    <Text>
                        <Timestamp timestamp={payment?.createdAt} />
                    </Text>
                </div>
                <div className="flex justfy-start flex-col items-start border-l-2 pr-8 pl-2">
                    <Text>User ID</Text>
                    <Text>{payment?.userId}</Text>
                </div>
            </div>
            <Timeline payment={payment} />
            <Subheading className="mt-8">Details</Subheading>
            <Divider />
            <DescriptionList>
                <DescriptionTerm>Amount authorized</DescriptionTerm>
                <DescriptionDetails>
                    <Amount
                        amount={payment?.amountAuthorized}
                        currencyCode={payment?.currencyCode}
                    />
                </DescriptionDetails>

                <DescriptionTerm>
                    Amount authorized without service fee
                </DescriptionTerm>
                <DescriptionDetails>
                    <Amount
                        amount={payment?.amountAuthorizedWithoutServiceFee}
                        currencyCode={payment?.currencyCode}
                    />
                </DescriptionDetails>

                <DescriptionTerm>Amount final</DescriptionTerm>
                <DescriptionDetails>
                    <Amount
                        amount={payment?.amountFinal}
                        currencyCode={payment?.currencyCode}
                    />
                </DescriptionDetails>

                <DescriptionTerm>Amount captured</DescriptionTerm>
                <DescriptionDetails>
                    <Amount
                        amount={payment?.amountCaptured}
                        currencyCode={payment?.currencyCode}
                    />
                </DescriptionDetails>

                <DescriptionTerm>Amount captured net</DescriptionTerm>
                <DescriptionDetails>
                    <Amount
                        amount={payment?.amountCapturedNet}
                        currencyCode={payment?.currencyCode}
                    />
                </DescriptionDetails>

                <DescriptionTerm>Amount to payout</DescriptionTerm>
                <DescriptionDetails>
                    <Amount
                        amount={payment?.amountToPayout}
                        currencyCode={payment?.currencyCode}
                    />
                </DescriptionDetails>
                <DescriptionTerm>Amount refunded</DescriptionTerm>
                <DescriptionDetails>
                    <Amount
                        amount={payment?.amountRefunded}
                        currencyCode={payment?.currencyCode}
                    />
                </DescriptionDetails>
                <DescriptionTerm>Service fee</DescriptionTerm>
                <DescriptionDetails>
                    <Amount
                        amount={payment?.serviceFee}
                        currencyCode={payment?.currencyCode}
                    />
                </DescriptionDetails>
                <DescriptionTerm>
                    Tax {payment?.salesTaxPercentage}%
                </DescriptionTerm>
                <DescriptionDetails>
                    <Amount
                        amount={payment?.taxAmount}
                        currencyCode={payment?.currencyCode}
                    />
                </DescriptionDetails>
            </DescriptionList>

            <Dialog open={openRefundDialog} onClose={setOpenRefundDialog}>
                {refundSuccessful === true ? (
                    <>
                        <DialogTitle>
                            <div className="flex flex-row gap-4 items-center justify-start">
                                Refund successful{" "}
                                <CheckCircleIcon className="h-6 w-6 text-lime-400" />
                            </div>
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={() => setOpenRefundDialog(false)}>
                                Ok
                            </Button>
                        </DialogActions>
                    </>
                ) : (
                    <>
                        <DialogTitle>Refund payment</DialogTitle>
                        <DialogDescription>
                            The refund will be reflected in the customer's bank
                            account 5 to 10 business days after processing. We
                            will inform the customer by email that a refund has
                            been initiated and when they can expect to see the
                            funds in their account.
                        </DialogDescription>
                        <DialogBody>
                            <Fieldset>
                                <FieldGroup>
                                    <AmountPicker
                                        setAmount={setAmountToRefund}
                                        amount={amountToRefund}
                                        currencyCode={payment?.currencyCode}
                                    />
                                    <Field>
                                        <Label>Refund reason</Label>
                                        <DropdownPicker
                                            setValue={(value) =>
                                                setRefundReason(
                                                    value as PaymentRefundReason,
                                                )
                                            }
                                            value={refundReason}
                                            options={[
                                                {
                                                    title: "Requested by user",
                                                    value: "requestedByUser",
                                                },
                                                {
                                                    title: "Expired uncaptured charge",
                                                    value: "expiredUncapturedCharge",
                                                },
                                                {
                                                    title: "Fraudulent",
                                                    value: "fraudulent",
                                                },
                                                {
                                                    title: "Duplicate",
                                                    value: "duplicate",
                                                },
                                            ]}
                                        />
                                    </Field>
                                </FieldGroup>
                            </Fieldset>
                        </DialogBody>
                        <DialogActions>
                            <Button
                                plain
                                onClick={() => setOpenRefundDialog(false)}
                                disabled={createRefundLoading}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleCreateRefund}
                                disabled={
                                    createRefundLoading ||
                                    amountToRefund === 0 ||
                                    refundReason === null
                                }
                            >
                                Refund
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default PaymentRoute;
/*
amountAuthorized: number; // amount of the payment that was initially authorized
    amountAuthorizedWithoutServiceFee: number; // amount of the payment that was authorized without the service fee
    amountFinal: number | null; // amount that was withdrawn from the user
    amountCaptured: number | null; // amount that was captured from the user
    amountToPayout: number | null;*/
